<template>
  <div id="pending-filing-slide-component">
    <template v-if="showFiling">
      <template v-if="hasCurrentObjectAndNotDiy">
        <ct-centered-spinner v-if="!loaded" />
        <order-requiring-attention
          v-else-if="objectsRequiringAttention.length"
        />
        <pending-filing-order
          v-else
          :current-object="currentObject"
          @next-slide="$emit('next-slide')"
        />
      </template>
      <mail-waiting-for-state
        v-else-if="stageProductSelectedType === 'diy'"
        @next-slide="$emit('next-slide')"
      />
      <no-order-item v-else />
    </template>

    <div v-else class="loading-slide">
      <slide-heading
        heading="Let’s review and see if we'll need any other information..."
      />

      <p>Reviewing data... checking inefficient agency bureaucracy...</p>
      <ct-fake-loading-bar
        class="loading-bar"
        :actually-complete="loaded"
        @progress-bar-complete="progressBarComplete = true"
      />
      <b-button
        :disabled="!progressBarComplete"
        variant="primary"
        aria-label="done button"
        @click="showFiling = true"
      >
        <span>Done!</span>
      </b-button>
    </div>

    <div v-if="showButtons" class="mt-3 mb-0 button-container">
      <div class="slide-viewer-button-row">
        <b-button
          v-if="!isCompleted"
          variant="default"
          aria-label="previous button"
          @click="back"
        >
          <fa-icon icon="chevron-left" class="fa-xs" />&nbsp;Previous
        </b-button>
        <b-button
          class="next-button"
          variant="primary"
          aria-label="continue button"
          @click="next"
        >
          <span class="px-1">
            Continue
          </span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { tagSession } from '@/common/modules/inspectlet'

export default {
  name: 'PendingFilingSlide',
  components: {
    CtCenteredSpinner:       () => import('@/components/shared/CtCenteredSpinner'),
    CtFakeLoadingBar:        () => import('@/components/shared/CtFakeLoadingBar'),
    MailWaitingForState:     () => import('./MailWaitingForState'),
    NoOrderItem:             () => import('./NoOrderItem'),
    OrderRequiringAttention: () => import('./OrderRequiringAttention'),
    PendingFilingOrder:      () => import('@/components/Filing/PendingFiling/PendingFilingOrder'),
    SlideHeading:            () => import('@/components/StagelineV2/shared/SlideHeading'),
  },
  props: {
    slide: null,
  },
  data() {
    return {
      loaded: false,
      progressBarComplete: false,
      showFiling: true,
    }
  },
  computed: {
    ...mapGetters('schema', [
      'currentObject',
    ]),
    ...mapGetters('stageline', [
      'company',
      'stageProductSelectedType',
      'objectsRequiringAttention',
      'processFilingObjects',
    ]),
    isCompleted() {
      const completed = [ 'completed', 'cancelled', 'expired', 'incomplete', 'refunded' ]
      return completed.includes(this.status)
    },
    showButtons() {
      return this.showFiling &&
         !this.isCompleted &&
         (!this.currentObject && this.stageProductSelectedType !== 'diy')
    },
    status() {
      return this.processFilingObjects[0]?.status
    },
    hasCurrentObjectAndNotDiy() {
      return this.currentObject && this.stageProductSelectedType !== 'diy'
    },
  },
  async mounted() {
    tagSession({
      stagelineV2: 'pendingFiling',
      entity_type: this.company?.entity_type,
      companyId: this.company?.id,
    })

    this.loaded = false
    this.showFiling = this.status !== 'awaiting-client-input'
    if (!this.processFilingObjects?.length) await this.reloadSlideORA()

    // TODO [STAG-2671] Update this to be dynamic to work for any filing and just auto complete any previous steps in current stage
    this.completeFirstTwoFormTheCompanyStepsIfFormationStatusIsCompleted()
    await this.setCurrentObject()
    this.loaded = true
  },
  methods: {
    ...mapActions('schema', [
      'loadObject',
    ]),
    ...mapActions('stageline', [
      'completeFirstTwoFormTheCompanyStepsIfFormationStatusIsCompleted',
      'goToPreviousSlide',
      'reloadSlideORA',
      'setCurrentObjectId',
      'setSlideLoaded',
    ]),
    back() {
      this.setSlideLoaded(false)
      this.goToPreviousSlide()
    },
    next() {
      this.$emit('next-slide')
    },
    async setCurrentObject() {
      if (this.processFilingObjects.length)
      {
        let object = null

        if (this.currentObjectId) {
          object = this.processFilingObjects.find(o => o.object_id === this.currentObjectId) || null
        }

        if (object == null) {
          object = this.objectsRequiringAttention.length ?
            this.objectsRequiringAttention[0] :
            this.processFilingObjects[0]
          await this.setCurrentObjectId(object.object_id)
        }

        await this.loadObject({ object_type: object.object_type, object_id: object.object_id })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#pending-filing-slide-component {
  width: 100%;

  .loading-slide {
    text-align: center;

    h3 {
      margin-bottom: 2em;
    }

    .loading-bar {
      margin: 0 35% 2em;

      ::v-deep .progress-bar {
        background-color: #7D64D8;
      }
    }
  }
  .button-container {
    float: right;
  }
}
</style>
