var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "pending-filing-slide-component" } },
    [
      _vm.showFiling
        ? [
            _vm.hasCurrentObjectAndNotDiy
              ? [
                  !_vm.loaded
                    ? _c("ct-centered-spinner")
                    : _vm.objectsRequiringAttention.length
                    ? _c("order-requiring-attention")
                    : _c("pending-filing-order", {
                        attrs: { "current-object": _vm.currentObject },
                        on: {
                          "next-slide": function ($event) {
                            return _vm.$emit("next-slide")
                          },
                        },
                      }),
                ]
              : _vm.stageProductSelectedType === "diy"
              ? _c("mail-waiting-for-state", {
                  on: {
                    "next-slide": function ($event) {
                      return _vm.$emit("next-slide")
                    },
                  },
                })
              : _c("no-order-item"),
          ]
        : _c(
            "div",
            { staticClass: "loading-slide" },
            [
              _c("slide-heading", {
                attrs: {
                  heading:
                    "Let’s review and see if we'll need any other information...",
                },
              }),
              _c("p", [
                _vm._v(
                  "Reviewing data... checking inefficient agency bureaucracy..."
                ),
              ]),
              _c("ct-fake-loading-bar", {
                staticClass: "loading-bar",
                attrs: { "actually-complete": _vm.loaded },
                on: {
                  "progress-bar-complete": function ($event) {
                    _vm.progressBarComplete = true
                  },
                },
              }),
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: !_vm.progressBarComplete,
                    variant: "primary",
                    "aria-label": "done button",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showFiling = true
                    },
                  },
                },
                [_c("span", [_vm._v("Done!")])]
              ),
            ],
            1
          ),
      _vm.showButtons
        ? _c("div", { staticClass: "mt-3 mb-0 button-container" }, [
            _c(
              "div",
              { staticClass: "slide-viewer-button-row" },
              [
                !_vm.isCompleted
                  ? _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "default",
                          "aria-label": "previous button",
                        },
                        on: { click: _vm.back },
                      },
                      [
                        _c("fa-icon", {
                          staticClass: "fa-xs",
                          attrs: { icon: "chevron-left" },
                        }),
                        _vm._v(" Previous\n      "),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-button",
                  {
                    staticClass: "next-button",
                    attrs: {
                      variant: "primary",
                      "aria-label": "continue button",
                    },
                    on: { click: _vm.next },
                  },
                  [
                    _c("span", { staticClass: "px-1" }, [
                      _vm._v("\n          Continue\n        "),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }